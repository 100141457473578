import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	Permission,
	permissionBasedNavigation_Query$data,
} from "@relay/permissionBasedNavigation_Query.graphql";
import { ReduxState } from "@store/index";

export type CurrentUserData = permissionBasedNavigation_Query$data["Viewer"]["Auth"]["currentUser"];

export type CurrentUserState = {
	currentUser?: CurrentUserData;
};

const INITIAL_STATE: CurrentUserState = {};

const currentUserSlice = createSlice({
	name: "current-user",
	initialState: INITIAL_STATE,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<CurrentUserData>) => {
			if (!action.payload) {
				state.currentUser = undefined;
				return;
			}
			state.currentUser = {
				...action.payload,
				permissionsInAccount: [...(action.payload?.permissionsInAccount || [])],
			};
		},
	},
});

export const { setCurrentUser } = currentUserSlice.actions;
export const CurrentUserSliceReducer = currentUserSlice.reducer;

const selectCurrentUserSlice = (state: ReduxState) => state.currentUser;

export const selectCurrentUser = createSelector(selectCurrentUserSlice, (e) => e.currentUser);

export const selectCurrentUserId = createSelector(selectCurrentUser, (e) => e?.user.id);

export const selectPermissionsInAccount = createSelector(
	selectCurrentUserSlice,
	(state) => state.currentUser?.permissionsInAccount || [],
);

export const selectHasPermission = createSelector(
	[
		// Usual first input - extract value from `state`
		selectPermissionsInAccount,
		// Take the second arg, `category`, and forward to the output selector
		(state: any, necessaryPermission: Permission) => necessaryPermission,
	],
	// Output selector gets (`items, category)` as args
	(permissions, necessaryPermission) =>
		permissions.includes(necessaryPermission) ||
		permissions.includes("UserInAccountPermission_System_Owner"),
);
