import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import { BrowserRouter } from "react-router-dom";
import { PageViewTracker } from "@analytics/PageViewTracker";
import { UserNotifications } from "@components/user-notifications";
import { BaseScreenSkeleton } from "@navigation/base-screen";
import { FilteredRoutes } from "@navigation/filtered-routes";
import { PERMISSION_QUERY } from "@navigation/permission-based-navigation/permission-based-navigation.graphql";
import {
	PermissionBasedNavigationProps,
	PermissionBasedNavigationPropsWithQuery,
} from "@navigation/permission-based-navigation/permission-based-navigation.types";
import { permissionBasedNavigation_Query } from "@relay/permissionBasedNavigation_Query.graphql";
import { CookieBannerScreen } from "@screens/cookie-banner";
import { PlacementContext } from "@screens/placement/placement.context";
import { selectIsLoggedIn, selectLoginData } from "@slices/auth/auth.selectors";
import { setCurrentUser } from "@slices/CurrentUserSlice";
import { setActiveFeatureToggles } from "@slices/feature-toggles-slice";

export const PermissionBasedNavigationWithPreloadedQuery = ({
	routes,
	forbiddenRoute,
	queryRef,
}: PermissionBasedNavigationPropsWithQuery) => {
	const [placementLinkId, setPlacementLinkId] = useState<string>("");

	const dispatch = useDispatch();
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const query = usePreloadedQuery<permissionBasedNavigation_Query>(PERMISSION_QUERY, queryRef);
	const permissions = query.Viewer.Auth.currentUser?.permissionsInAccount ?? [];

	const contextValue = { placementLinkId, setPlacementLinkId };

	useEffect(() => {
		if (query.Viewer.Auth.currentUser && isLoggedIn) {
			dispatch(setCurrentUser(query.Viewer.Auth.currentUser));
		}
	}, [query.Viewer.Auth.currentUser, dispatch, isLoggedIn]);

	useEffect(() => {
		if (query.FeatureToggle.ActiveFeatureToggleIds) {
			dispatch(setActiveFeatureToggles(query.FeatureToggle.ActiveFeatureToggleIds));
		}
	}, [query.FeatureToggle.ActiveFeatureToggleIds, dispatch]);

	return (
		<PlacementContext.Provider value={contextValue}>
			<BrowserRouter>
				<FilteredRoutes
					routes={routes}
					forbiddenRoute={forbiddenRoute}
					permissions={permissions}
				/>
				<CookieBannerScreen />
				<PageViewTracker />
				<UserNotifications viewerQueryTypeFragmentRef={query.Viewer} />
			</BrowserRouter>
		</PlacementContext.Provider>
	);
};

export const PermissionBasedNavigation = (props: PermissionBasedNavigationProps) => {
	const [queryReference, loadQuery] =
		useQueryLoader<permissionBasedNavigation_Query>(PERMISSION_QUERY);

	const loginData = useSelector(selectLoginData);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery, loginData]);

	return (
		<Suspense fallback={<BaseScreenSkeleton />}>
			{queryReference && (
				<PermissionBasedNavigationWithPreloadedQuery queryRef={queryReference} {...props} />
			)}
		</Suspense>
	);
};
