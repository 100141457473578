import { authMiddleware } from "react-relay-network-modern";
import { selectJwtClaimData } from "@slices/auth/auth.selectors";
import { logout, refreshLogin } from "@slices/auth/auth.slice";
import { ReduxStore } from "@store/index";

export const refreshJWTToken = () => {
	const state = ReduxStore.getState();
	const accountId = selectJwtClaimData(state)?.accountId;
	return fetch(`${process.env.REACT_APP_API_BASE}/api/refresh-token`, {
		method: "POST",
		headers: new Headers({ "content-type": "application/json" }),
		body: JSON.stringify({
			refreshToken: state.auth.loginData?.refreshToken,
			accountId: accountId,
		}),
	})
		.then((res) => res.json())
		.then((json) => {
			const token = json.accessToken;
			ReduxStore.dispatch(refreshLogin({ loginData: json }));
			return token;
		})
		.catch(() => {
			ReduxStore.dispatch(logout());
		});
};

export const JwtMiddleware = authMiddleware({
	allowEmptyToken: true,
	token: () => ReduxStore.getState().auth.loginData?.accessToken || "",
	tokenRefreshPromise: refreshJWTToken,
});
