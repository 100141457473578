import { useFormik } from "formik";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { forwardRef, MouseEventHandler, useImperativeHandle, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useMutation, useRefetchableFragment } from "react-relay";
import * as Yup from "yup";
import { Avatar } from "@components/avatar";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { RadioButtonStatus } from "@components/radio-button";
import { RadioOption } from "@components/radio-option";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { ValidatedInputText } from "@components/validated-input-text";
import { usePermissions } from "@hooks/use-permissions";
import { useToast } from "@hooks/useToast";
import { accountTab_EditBusinessBaseDataMutation } from "@relay/accountTab_EditBusinessBaseDataMutation.graphql";
import { accountTab_EditPrivateBaseDataMutation } from "@relay/accountTab_EditPrivateBaseDataMutation.graphql";
import { accountTab_SetAccountNameMutation } from "@relay/accountTab_SetAccountNameMutation.graphql";
import { selectCurrentAccountId } from "@slices/auth/auth.selectors";
import { error100 } from "@themes/colors";
import {
	EDIT_BUSINESS_BASE_DATA_MUTATION,
	EDIT_PRIVATE_BASE_DATA_MUTATION,
	QUERY_FRAGMENT,
	SET_ACCOUNT_NAME_MUTATION,
} from "./account-tab.graphql";
import {
	AccountTypeWrapper,
	AvatarWrapper,
	PermanentActionsButton,
	RightColWrapper,
	Wrapper,
} from "./account-tab.styles";
import {
	AccountFormState,
	AccountTabProps,
	AccountTabRef,
	AccountType,
	BusinessAccountSubformState,
	PrivateAccountSubformState,
} from "./account-tab.types";
import { isBusinessForm, isPrivateForm } from "./account-tab.utils";
import { AccountContextMenu } from "./parts/account-context-menu/account-context-menu.component";
import { BusinessAccountForm } from "./parts/business-account-form";
import {
	BusinessAccountFormRef,
	BusinessAccountFormState,
} from "./parts/business-account-form/business-account-form.types";
import { PrivateAccountForm } from "./parts/private-account-form";
import {
	PrivateAccountFormRef,
	PrivateAccountFormState,
} from "./parts/private-account-form/private-account-form.types";

export const AccountTab = forwardRef<AccountTabRef, AccountTabProps>(function AccountTab(
	{ queryFragmentRef, onLoadingStateChanged },
	ref,
) {
	const [query, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const [editBusinessBaseData] = useMutation<accountTab_EditBusinessBaseDataMutation>(
		EDIT_BUSINESS_BASE_DATA_MUTATION,
	);
	const [editPrivateBaseData] = useMutation<accountTab_EditPrivateBaseDataMutation>(
		EDIT_PRIVATE_BASE_DATA_MUTATION,
	);
	const [setAccountName] =
		useMutation<accountTab_SetAccountNameMutation>(SET_ACCOUNT_NAME_MUTATION);

	const { showSuccess, showError } = useToast();

	const { isAccountOwner, isAZAVAccount } = usePermissions();

	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
	const businessAccountFormRef = useRef<BusinessAccountFormRef>(null);
	const privateAccountFormRef = useRef<PrivateAccountFormRef>(null);

	const currentAccountId = useSelector(selectCurrentAccountId);
	const accountName = useMemo(
		() =>
			query?.Viewer.Auth.currentUser?.accounts.find((a) => a.id == currentAccountId)?.name ??
			"Kontoname",
		[query?.Viewer.Auth.currentUser?.accounts, currentAccountId],
	);
	const refresh = () => {
		refetch({}, { fetchPolicy: "store-and-network" });
	};
	const handleOnCompleted = () => {
		showSuccess({
			summary: "Erfolgreich gespeichert",
			detail: "Die Kontodaten wurden erfolgreich gespeichert.",
		});
		onLoadingStateChanged?.(false);
		refresh();
	};
	const handleOnError = () => {
		showError({
			summary: "Fehler beim Speichern",
			detail: "Die Kontodaten können nicht gepspeichert werden.",
		});
		onLoadingStateChanged?.(false);
	};

	const handleSaveAccountName = (values: AccountFormState) => {
		onLoadingStateChanged?.(true);
		setAccountName({
			variables: {
				input: {
					id: currentAccountId ?? "",
					name: values.accountName,
				},
			},
		});
	};

	const handleSaveBusinessBaseData = (values: Required<BusinessAccountSubformState>) => {
		onLoadingStateChanged?.(true);
		editBusinessBaseData({
			variables: {
				input: {
					baseData: {
						companyName: values.subform.companyName,
						billingOffice: values.subform.billingOffice,
						companyLegalForm: values.subform.companyLegalForm!,
						invoiceEmail: values.subform.invoiceEmail,
						street: values.subform.street,
						houseNumber: values.subform.houseNumber,
						postalCode: values.subform.postalCode,
						city: values.subform.city,
						countryCode: values.subform.countryCode,
						phoneNumber: values.subform.phoneNumber,
						taxData: {
							taxNumber: values.subform.taxNumber,
							taxIdentificationNumber: values.subform.taxIdentificationNumber,
						},
						accountData: {
							iban: values.subform.iban,
							bic: values.subform.bic,
						},
					},
				},
			},
			onCompleted: handleOnCompleted,
			onError: handleOnError,
		});
	};

	const handleSavePrivateBaseData = (values: Required<PrivateAccountSubformState>) => {
		onLoadingStateChanged?.(true);
		editPrivateBaseData({
			variables: {
				input: {
					baseData: {
						salutation: values.subform.salutation!,
						title: values.subform.title,
						firstName: values.subform.firstName,
						lastName: values.subform.lastName,
						street: values.subform.street,
						houseNumber: values.subform.houseNumber,
						postalCode: values.subform.postalCode,
						city: values.subform.city,
						countryCode: values.subform.countryCode,
						phoneNumber: values.subform.phoneNumber,
					},
				},
			},
			onCompleted: handleOnCompleted,
			onError: handleOnError,
		});
	};

	const initalIsBusinessAccount =
		query?.AccountBaseData.AccountBaseData.__typename === "BusinessBaseData";

	const form = useFormik<AccountFormState>({
		initialValues: {
			accountType: initalIsBusinessAccount ? AccountType.Bussiness : AccountType.Private,
			accountName: accountName,
			subform: undefined,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			accountName: Yup.string().required("Bitte gib einen Kontonamen ein."),
			accountType: Yup.mixed<AccountType>()
				.oneOf(Object.values(AccountType))
				.required("Bitte wähle einen Kontotyp aus."),
			subform: Yup.mixed().required(),
		}),
		onSubmit: (values) => {
			handleSaveAccountName(values);
			if (isBusinessForm(values)) {
				handleSaveBusinessBaseData(values);
			}
			if (isPrivateForm(values)) {
				handleSavePrivateBaseData(values);
			}
		},
	});

	const isBusinessAccount = form.values.accountType === AccountType.Bussiness;

	const handleOnSave = async () => {
		await form.setFieldValue("subform", undefined);
		await form.submitForm();
		isBusinessAccount
			? businessAccountFormRef.current?.submit()
			: privateAccountFormRef.current?.submit();
	};

	useImperativeHandle(ref, () => ({
		save: handleOnSave,
	}));

	const handlePrivateAccountOnChange = async (event: RadioButtonChangeEvent) => {
		if (!event.checked) return;
		await form.setFieldValue("accountType", AccountType.Private);
		if (!initalIsBusinessAccount) return;
		await form.setFieldValue("subform", undefined);
		privateAccountFormRef.current?.reset({
			values: {
				title: "",
				firstName: "",
				lastName: "",
				street: "",
				houseNumber: "",
				postalCode: "",
				city: "",
				countryCode: "DE",
				phoneNumber: "",
			},
		});
	};

	const handleBusinessAccountOnChange = async (event: RadioButtonChangeEvent) => {
		if (!event.checked) return;
		await form.setFieldValue("accountType", AccountType.Bussiness);
		if (initalIsBusinessAccount) return;
		await form.setFieldValue("subform", undefined);
		businessAccountFormRef.current?.reset({
			values: {
				companyName: "",
				billingOffice: "",
				companyLegalForm: undefined,
				invoiceEmail: "",
				street: "",
				houseNumber: "",
				postalCode: "",
				city: "",
				countryCode: "DE",
				phoneNumber: "",
				iban: "",
				bic: "",
				taxIdentificationNumber: "",
				taxNumber: "",
			},
		});
	};

	const handleSubformOnSubmit = async (
		values: BusinessAccountFormState | PrivateAccountFormState,
	) => {
		await form.setFieldValue("subform", values);
		await form.submitForm();
	};

	const handleOnShowContextMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		overlayRef.current?.toggle(event, event.currentTarget);
	};

	const disableEdit = !isAccountOwner;

	return (
		<Wrapper>
			<AvatarWrapper>
				<Avatar name={form.values.accountName ?? "Unbekannt"} sizeInRem={9} />
			</AvatarWrapper>
			<RightColWrapper>
				<ValidatedInputText
					status={disableEdit ? "disabled" : undefined}
					formikConfig={form}
					name="accountName"
					label="Kontoname*"
					placeholder="..."
				/>
				<Divider />
				<AccountTypeWrapper>
					<RadioOption
						value="private"
						checked={!isBusinessAccount}
						label="Privatkonto"
						status={
							disableEdit || isAZAVAccount ? RadioButtonStatus.Disabled : undefined
						}
						onChange={handlePrivateAccountOnChange}
					/>
					<RadioOption
						value="business"
						checked={isBusinessAccount}
						label="Geschäftskonto"
						status={disableEdit ? RadioButtonStatus.Disabled : undefined}
						onChange={handleBusinessAccountOnChange}
					/>
				</AccountTypeWrapper>
				<Divider />
				{disableEdit ? null : isBusinessAccount ? (
					<BusinessAccountForm
						ref={businessAccountFormRef}
						baseDataFragmentRef={query?.AccountBaseData.AccountBaseData}
						onSubmit={handleSubformOnSubmit}
					/>
				) : (
					<PrivateAccountForm
						ref={privateAccountFormRef}
						baseDataFragmentRef={query?.AccountBaseData.AccountBaseData}
						onSubmit={handleSubformOnSubmit}
					/>
				)}
				<Divider />
				<PermanentActionsButton onClick={handleOnShowContextMenu}>
					<Icon icon="contextMenu" sizeInRem={1.5} tkaColor={error100} />
					Permanente Aktionen
				</PermanentActionsButton>
				{query?.Viewer.Auth && (
					<AccountContextMenu
						ref={overlayRef}
						authViewerSchemaFragmentRef={query?.Viewer.Auth}
					/>
				)}
			</RightColWrapper>
		</Wrapper>
	);
});
