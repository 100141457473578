import { type FC } from "react";
import { useDispatch } from "react-redux";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { logout } from "@slices/auth/auth.slice";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { type SuccessDeleteAccountModalProps } from "./success-delete-account-modal.types";
import { Wrapper } from "../confirm-delete-account-modal/confirm-delete-account-modal.styles";

export const SuccessDeleteAccountModal: FC<SuccessDeleteAccountModalProps> = ({
	isVisible,
	onDismiss,
	accountName,
}) => {
	const dispatch = useDispatch();
	const handleOnDismiss = () => {
		dispatch(logout());
		onDismiss();
		window.location.href = "/";
	};
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				primaryButtonLabel="Okay"
				onPrimaryButtonClick={handleOnDismiss}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<H1Span>Konto gelöscht</H1Span>
					<TkaP2Span>
						Dein Konto “{accountName}” wurde erfolgreich gelöscht und kann nicht
						wiederhergestellt werden.
					</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
