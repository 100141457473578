import * as Sentry from "@sentry/react";
import {
	EpicH2Span,
	EpicH3Span,
	EpicP1Span,
	EpicP2Span,
	EpicP3Span,
	PathParams,
} from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@components/button";
import { ControlButton } from "@components/control-button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { Icon } from "@components/icon";
import { IconWrapper } from "@components/icon/icon.styles";
import { InputText } from "@components/input-text";
import { Label } from "@components/label";
import { RootCard } from "@components/root-card";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";

import { withSuspense } from "@components/with-suspense";
import { useWindowSize } from "@hooks/use-window-size";
import { shoppingCartProducts_BaseDataFragment$key } from "@relay/shoppingCartProducts_BaseDataFragment.graphql";
import {
	shoppingCartProducts_CreateOrderMutation,
	shoppingCartProducts_CreateOrderMutation$data,
} from "@relay/shoppingCartProducts_CreateOrderMutation.graphql";
import { shoppingCartProducts_OrderFragment$key } from "@relay/shoppingCartProducts_OrderFragment.graphql";
import { shoppingCartProducts_Query } from "@relay/shoppingCartProducts_Query.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import {
	cloneCartSelection,
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getProductItems,
} from "@screens/shopping-cart-products/shopping-cart-products.utils";
import { selectIsLoggedIn } from "@slices/auth/auth.selectors";
import { shade20, success100 } from "@themes/colors";
import { H1Span } from "@themes/font-tags";
import { formatCurrency } from "@utils/currency";
import { formatDateTime } from "@utils/date-utils";
import { getGrossPriceByCountryCode } from "@utils/prices";
import {
	BASE_DATA_FRAGMENT,
	ORDER_FRAGMENT,
	QUERY,
	UPDATE_CART_MUTATION,
} from "./shopping-cart-products.graphql";
import {
	ControlsPriceWrapper,
	CountControls,
	DiscountCodeWrapper,
	DiscountPercentWrapper,
	DiscountTitleTagWrapper,
	DiscountTitleWrapper,
	DiscountWrapper,
	Divider,
	HeaderWrapper,
	ItemPriceWrapper,
	MoreProductsWrapper,
	PriceLeftGroupWrapper,
	PriceMonthlyWrapper,
	PriceRightGroupWrapper,
	PriceRowsWrapper,
	PriceWrapper,
	ProductCardWrapper,
	ProductItem,
	ProductsList,
	ScreenWrapper,
} from "./shopping-cart-products.styles";
import { CartSelection } from "./shopping-cart-products.types";
import { gtmTrackAddToCart } from "../../analytics/google-tag-manager";

//TODO: add-translations
export const ShoppingCartProductsScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const {
		node,
		AccountBaseData,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartProducts_Query>(QUERY, {
		orderId: shoppingCartId ?? "",
		skip: !shoppingCartId,
		isNotLoggedIn: !isLoggedIn,
	});

	const accountBaseData = useFragment<shoppingCartProducts_BaseDataFragment$key>(
		BASE_DATA_FRAGMENT,
		AccountBaseData?.AccountBaseData!,
	);

	const order = useFragment<shoppingCartProducts_OrderFragment$key>(ORDER_FRAGMENT, node!);
	const userCountryCode = accountBaseData?.countryCode || "DE";

	const [updateCart] =
		useMutation<shoppingCartProducts_CreateOrderMutation>(UPDATE_CART_MUTATION);
	const navigate = useNavigate();

	const [discountCodeInput, setDiscountCodeInput] = useState("");
	const [hasDiscountCodeError, setHasDiscountCodeError] = useState(false);

	const { isMediumUp } = useWindowSize();

	const cart = order?.cart;
	const products = getProductItems(cart);

	const cartDiscounts = getCartDiscountItems(cart) ?? [];
	const cartLimitedDiscounts = getCartLimitedDiscountItems(cart) ?? [];
	const discounts = [...cartLimitedDiscounts, ...cartDiscounts];
	const moreProducts = cart?.otherAvailableProducts.edges ?? [];

	const hasDiscountCode = cartDiscounts.length > 0;
	const hasDiscounts = discounts.length > 0;
	const hasProducts = products?.length != 0;
	const hasMoreProducts = moreProducts.length > 0;

	const handleGoToOffers = () => {
		navigate(Path.progression.path);
	};

	const handleUpdateCart = (cartSelection: CartSelection, productId: string | undefined) => {
		const amountBefore = getProductItems(order?.cart)?.find(
			(p) => p.product?.id === productId,
		)?.amount;
		updateCart({
			variables: {
				input: {
					orderId: order.id,
					cartSelection,
				},
			},
			onCompleted: (response) => {
				const hasAllDiscountCodes = cartSelection.selectedDiscountCodes.every(
					(code) =>
						response.Billing.updateCart?.order.cart?.items.find(
							(item) => item?.itemType === "CartDiscount" && item.code === code,
						) !== undefined,
				);
				setHasDiscountCodeError(!hasAllDiscountCodes);
				trackAddToCart(response, amountBefore ?? 0, productId);
			},
		});
	};

	const trackAddToCart = (
		response: shoppingCartProducts_CreateOrderMutation$data,
		amountBefore: number,
		productId?: string,
	) => {
		try {
			const newAmount =
				response.Billing.updateCart?.order.cart?.items.find(
					(p) => p.product?.id === productId,
				)?.amount ?? 0;
			if (amountBefore < newAmount) {
				const cartAfterUpdateMutation = response.Billing.updateCart?.order.cart;
				const cartTotalBefore = cart?.taxTotals?.netPrice || 0;
				const cartTotalAfter = cartAfterUpdateMutation?.taxTotals?.netPrice;
				const productName = cartAfterUpdateMutation?.items.find(
					(x) => x.product?.id === productId,
				)?.product?.title;
				const selectedProducts = cartAfterUpdateMutation?.selection.selectedProducts.map(
					(product) => ({
						base64EncodedProductId: product.product?.id ?? "",
						quantity: product.amount,
					}),
				);
				if (productName && cartTotalAfter && selectedProducts) {
					gtmTrackAddToCart(
						{
							base64EncodedProductId: productId ?? "",
							quantity: newAmount - amountBefore,
							productName: productName,
						},
						selectedProducts,
						cartTotalAfter - cartTotalBefore,
						cartAfterUpdateMutation?.taxTotals.netPrice || 0,
					);
				}
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	};

	const createIncreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount++;
			}
		});
		handleUpdateCart(cart, productId);
	};

	const createDecreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount--;
			}
		});
		handleUpdateCart(cart, productId);
	};

	const handleDiscountCodeOnChange = (text?: string) => {
		setDiscountCodeInput(text ?? "");
		setHasDiscountCodeError(false);
	};

	const handleSubmitDiscountCodeOnClick = () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = [discountCodeInput];
		handleUpdateCart(cart, undefined);
		setDiscountCodeInput("");
	};

	const createAddProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.push({
			amount: 1,
			productId,
		});
		handleUpdateCart(cart, productId);
	};

	const createRemoveProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts = cart.selectedProducts.filter(
			(item) => item.productId !== productId,
		);
		handleUpdateCart(cart, productId);
	};

	const createDeleteDiscountCodeOnClickHandler = (discountCode: string) => () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = cart.selectedDiscountCodes.filter(
			(item) => item !== discountCode,
		);
		handleUpdateCart(cart, undefined);
	};

	const handleNextOnClick = () => {
		if (isLoggedIn) navigate(Path.shoppingCart.withId(order.id).invoice.path);
		else navigate(`${Path.login.path}?orderId=${order.id}`);
	};

	const getRemoveControlDisabled = (amount?: number) => (amount ?? 1) <= 1;

	const discountGross = order.cart?.taxTotals?.discountTotal;
	const smallestMonthlyRate = order.cart?.totals.monthlyOptions.length
		? order.cart?.totals.monthlyOptions
				.slice()
				.sort((a, b) => a.monthlyRate.grossPrice - b.monthlyRate.grossPrice)[0].monthlyRate
				.grossPrice
		: undefined;

	const discountCodeInputStatus = hasDiscountCodeError ? "error" : undefined;
	const discountCodeError = hasDiscountCodeError ? "Rabattcode nicht gültig" : undefined;

	return (
		<ShoppingCartTemplate
			onNext={handleNextOnClick}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<ScreenWrapper>
				<HeaderWrapper>
					<H1Span>Warenkorb</H1Span>
					{hasProducts && (
						<EpicP2Span>
							Wähle weitere Produkte aus oder füge einen Rabatt-Code hinzu.
						</EpicP2Span>
					)}
				</HeaderWrapper>
				<EmptyPlaceholder
					isVisible={!hasProducts}
					iconName="shoppingBasketSad1"
					title="Hier sieht es aber leer aus ..."
					subtitle="Schau dich doch bei unseren Kursen um und finde das passende Angebot!"
					buttonLabel={"Zu den Kursen"}
					onClick={handleGoToOffers}
				>
					{products?.map(({ product, amount }) => {
						const contractPartner =
							product?.data?.licenseDefinition?.data.rootInfo?.contractPartner
								?.data ??
							product?.data?.licenseDefinition?.data.coachingInfo?.contractPartner
								?.data;

						const grossPrice = getGrossPriceByCountryCode(userCountryCode, [
							...(product?.extensions ?? []),
						]);
						return (
							<ProductItem key={product?.id}>
								<ProductCardWrapper>
									<RootCard
										title={product?.title ?? ""}
										imageUrl={
											product?.data?.licenseDefinition?.data.rootInfo?.image
												?.url ??
											product?.data?.licenseDefinition?.data.coachingInfo
												?.image?.url
										}
										partnerName={contractPartner?.name}
										partnerUrl={contractPartner?.linkToAGB ?? undefined}
									/>
									{!isMediumUp && (
										<>
											<IconWrapper>
												<Icon
													sizeInRem={1}
													tkaColor={shade20}
													icon="trash"
													onClick={createRemoveProductOnClickHandler(
														product?.id,
													)}
												/>
											</IconWrapper>
										</>
									)}
								</ProductCardWrapper>
								<ControlsPriceWrapper>
									<CountControls>
										{isMediumUp && (
											<Icon
												sizeInRem={1}
												tkaColor={shade20}
												icon="trash"
												onClick={createRemoveProductOnClickHandler(
													product?.id,
												)}
											/>
										)}
										<ControlButton
											iconName="remove"
											disabled={getRemoveControlDisabled(amount)}
											onClick={createDecreaseProductHandler(product?.id)}
										/>
										<EpicH2Span>{amount}</EpicH2Span>
										<ControlButton
											iconName="add"
											onClick={createIncreaseProductHandler(product?.id)}
										/>
									</CountControls>
									<ItemPriceWrapper>
										<EpicH3Span>{formatCurrency(product?.netPrice)}</EpicH3Span>
										{!product?.isTaxFree ? (
											<EpicP3Span>
												{formatCurrency(grossPrice) +
													(userCountryCode === "AT"
														? " inkl. USt."
														: " inkl. MwSt.")}
											</EpicP3Span>
										) : (
											<EpicP3Span>
												{userCountryCode === "AT"
													? " (USt.befreit)"
													: " (MwSt.befreit)"}
											</EpicP3Span>
										)}
									</ItemPriceWrapper>
								</ControlsPriceWrapper>
							</ProductItem>
						);
					})}
					{hasDiscounts && <Divider />}
					{discounts?.map((discount) => (
						<DiscountWrapper key={discount.code}>
							<DiscountTitleTagWrapper>
								{discount.validUntil && (
									<Label
										label={`Noch bis ${formatDateTime(discount.validUntil)}`}
										severity="info"
									/>
								)}
								<DiscountTitleWrapper>
									<EpicH2Span>{discount.title ?? "Rabatt"}</EpicH2Span>
									<EpicP1Span>{discount.code ?? "Rabatt Aktion"}</EpicP1Span>
								</DiscountTitleWrapper>
							</DiscountTitleTagWrapper>
							<DiscountPercentWrapper>
								{discount.itemType === "CartDiscount" && discount.code && (
									<Icon
										sizeInRem={1}
										tkaColor={shade20}
										icon="trash"
										onClick={createDeleteDiscountCodeOnClickHandler(
											discount.code,
										)}
									/>
								)}
								<EpicH2Span>-{discount.value?.percentage}%</EpicH2Span>
							</DiscountPercentWrapper>
						</DiscountWrapper>
					))}
					<Divider />
					<PriceWrapper>
						<PriceRowsWrapper>
							<PriceLeftGroupWrapper>
								<EpicP1Span>Zwischensumme</EpicP1Span>
								{discountGross !== undefined && discountGross > 0 && (
									<EpicP1Span color={success100.getColor()}>
										Rabatt -{order.cart?.totals.appliedDiscountPercentage}%
									</EpicP1Span>
								)}
								{cart?.taxTotals?.taxTotal && cart?.taxTotals?.taxTotal > 0 && (
									<EpicP1Span>
										{userCountryCode === "AT"
											? "Umsatzsteuer 20%"
											: "Mehrwertsteuer 19%"}
									</EpicP1Span>
								)}
								{cart?.taxTotals?.hasTaxFreeProducts && (
									<EpicP1Span>
										{userCountryCode === "AT"
											? "Umsatzsteuer 0%"
											: "Mehrwertsteuer 0%"}
									</EpicP1Span>
								)}
								<EpicH2Span>Gesamtpreis</EpicH2Span>
							</PriceLeftGroupWrapper>
							<PriceRightGroupWrapper>
								<EpicP1Span>{formatCurrency(cart?.taxTotals?.netPrice)}</EpicP1Span>
								{discountGross !== undefined && discountGross > 0 && (
									<EpicP1Span color={success100.getColor()}>
										{formatCurrency(discountGross)}
									</EpicP1Span>
								)}
								{cart?.taxTotals?.taxTotal && cart?.taxTotals?.taxTotal > 0 && (
									<EpicP1Span>
										{formatCurrency(cart?.taxTotals?.taxTotal)}
									</EpicP1Span>
								)}
								{cart?.taxTotals?.hasTaxFreeProducts && (
									<EpicP1Span>{formatCurrency(0)}</EpicP1Span>
								)}
								<EpicH2Span>
									{formatCurrency(cart?.taxTotals?.grossPrice)}
								</EpicH2Span>
							</PriceRightGroupWrapper>
						</PriceRowsWrapper>
						<PriceMonthlyWrapper>
							<EpicP1Span>
								Oder monatlich <wbr />
								<EpicP1Span>ab {formatCurrency(smallestMonthlyRate)}</EpicP1Span>
							</EpicP1Span>
						</PriceMonthlyWrapper>
					</PriceWrapper>
					{(!hasDiscountCode || hasMoreProducts) && <Divider />}
					{!hasDiscountCode && (
						<DiscountCodeWrapper>
							<InputText
								icon="discountBubble1"
								placeholder="Rabattcode"
								value={discountCodeInput}
								onChange={handleDiscountCodeOnChange}
								status={discountCodeInputStatus}
								smallTextBelow={discountCodeError}
							/>
							<Button
								disabled={discountCodeInput.length === 0}
								label="Bestätigen"
								colorVersion="tertiary"
								onClick={handleSubmitDiscountCodeOnClick}
							/>
						</DiscountCodeWrapper>
					)}
				</EmptyPlaceholder>
				{hasMoreProducts && (
					<MoreProductsWrapper>
						<EpicH2Span>Weitere Produkte</EpicH2Span>
						<ProductsList>
							{moreProducts.map((item, index) => {
								const contractPartner =
									item?.node?.data?.licenseDefinition?.data.rootInfo
										?.contractPartner?.data ??
									item?.node?.data?.licenseDefinition?.data.coachingInfo
										?.contractPartner?.data;
								return (
									<RootCard
										key={`${item?.node.id}_${index}`}
										title={item?.node.title ?? ""}
										imageUrl={
											item?.node?.data?.licenseDefinition?.data.rootInfo
												?.image?.url ??
											item?.node?.data?.licenseDefinition?.data.coachingInfo
												?.image?.url
										}
										partnerName={contractPartner?.name}
										partnerUrl={contractPartner?.linkToAGB ?? undefined}
										onClick={createAddProductOnClickHandler(item?.node.id)}
									/>
								);
							})}
						</ProductsList>
					</MoreProductsWrapper>
				)}
			</ScreenWrapper>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartProductsScreen = withSuspense(
	ShoppingCartProductsScreenComponent,
	ShoppingCartTemplateSkeleton,
);
