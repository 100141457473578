import { Divider } from "primereact/divider";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AccountSelectionPanel } from "@components/account-selection-panel/account-selection-panel.component";
import { withSuspense } from "@components/with-suspense";
import { accountSelection_Query } from "@relay/accountSelection_Query.graphql";
import { Path } from "@router/paths";
import { QUERY } from "@screens/account-selection/account-selection.graphql";
import { AccountSelectionScreenSkeleton } from "@screens/account-selection/account-selection.skeleton";
import {
	dividerClass,
	nameWrapperClass,
	panelClass,
	panelWrapperClass,
	textWrapperClass,
} from "@screens/account-selection/account-selection.styles";
import { selectRedirectUrl } from "@slices/auth/auth.selectors";
import { H1Span, P3Span } from "@themes/font-tags";
import { UserAvatar } from "src/components/user-avatar";

export const AccountSelectionScreenComponent = () => {
	const auth = useLazyLoadQuery<accountSelection_Query>(QUERY, {
		fetchPolicy: "store-and-network",
	});
	const navigate = useNavigate();
	const redirectUrlAfterLogin = useSelector(selectRedirectUrl);

	const user = auth.Viewer.Auth.currentUser?.user;
	const moreThanOneAccount = auth.Viewer.Auth.currentUser?.accounts!.length! > 1;

	const handleOnAccountSelected = useCallback(() => {
		if (redirectUrlAfterLogin) {
			window.location.href = redirectUrlAfterLogin;
			return;
		}
		navigate(Path.progression.path, { replace: true });
	}, [navigate, redirectUrlAfterLogin]);

	useEffect(() => {
		if (!user) return;
		if (!moreThanOneAccount) handleOnAccountSelected();
	}, [user, moreThanOneAccount, handleOnAccountSelected]);

	if (!user || !moreThanOneAccount) return null;

	return (
		<div className={panelWrapperClass}>
			<div className={panelClass}>
				<UserAvatar userFragmentRef={user} sizeInRem={5} showGamificationPoints />
				<H1Span className={nameWrapperClass}>
					{user.extension?.firstName} {user.extension?.lastName}
				</H1Span>
				<Divider className={dividerClass} />
				<div className={textWrapperClass}>
					<H1Span>Bei welchem Konto willst du dich einloggen?</H1Span>
					<P3Span>Das Konto kannst du im Profil jederzeit wechseln</P3Span>
				</div>
				<AccountSelectionPanel
					authViewerFragmentRef={auth.Viewer.Auth}
					onAccountSelected={handleOnAccountSelected}
				/>
			</div>
		</div>
	);
};

export const AccountSelectionScreen = withSuspense(
	AccountSelectionScreenComponent,
	AccountSelectionScreenSkeleton,
);
