import graphql from "babel-plugin-relay/macro";

export const PERMISSION_QUERY = graphql`
	query permissionBasedNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						id
					}
					permissionsInAccount
				}
				...accountSwitchMenu_AuthViewerSchemaFragment
				...navbar_AuthViewerSchemaFragment
				...profileMenu_AuthViewerSchemaFragment
			}
			...userNotifications_ViewerQueryTypeFragment
		}
		FeatureToggle {
			ActiveFeatureToggleIds
		}
	}
`;
