import React from "react";
import { useSelector } from "react-redux";
import { Header } from "@components/header";
import { HubspotGlobalStyle, HubspotHiddenGlobalStyle } from "@components/hubspot-style";
import { Navbar } from "@components/navbar";
import { useWindowSize } from "@hooks/use-window-size";
import { SidemenuLayout } from "@layouts/sidemenu-layout";
import { selectIsAvgsClient } from "@slices/auth/auth.selectors";
import {
	ChildrenWrapper,
	NavbarWrapper,
	ScreenWrapper,
	Wrapper,
} from "./screen-with-navbar-sidemenu-layout.styles";
import { ScreenWithNavbarSidemenuLayoutProps } from "./screen-with-navbar-sidemenu-layout.types";

export const ScreenWithNavbarSidemenuLayout = ({
	canGoBack,
	onBackClick,
	headerLeftIcon,
	noPadding,
	noSideMenuPadding,
	noContentPadding,
	contentContainerId,
	sideMenu,
	bottomContent,
	keepSidemenuContentOnMobile,
	children,
}: ScreenWithNavbarSidemenuLayoutProps) => {
	const { isXLargeUp } = useWindowSize();
	const hideNavBar = !isXLargeUp && bottomContent;
	const isAvgsClient = useSelector(selectIsAvgsClient);

	return (
		<Wrapper>
			{!hideNavBar && (
				<NavbarWrapper>
					<Navbar />
				</NavbarWrapper>
			)}
			<ScreenWrapper>
				<Header
					canGoBack={canGoBack}
					onLeftItemClick={onBackClick}
					leftIcon={headerLeftIcon}
				/>
				<ChildrenWrapper>
					<SidemenuLayout
						noPadding={noPadding}
						noSideMenuPadding={noSideMenuPadding}
						noContentPadding={noContentPadding}
						contentContainerId={contentContainerId}
						sideMenu={sideMenu}
						bottomContent={bottomContent}
						keepSidemenuContentOnMobile={keepSidemenuContentOnMobile}
					>
						{children}
					</SidemenuLayout>
				</ChildrenWrapper>
			</ScreenWrapper>
			{!isXLargeUp && !bottomContent && <HubspotGlobalStyle />}
			{isAvgsClient && <HubspotHiddenGlobalStyle />}
		</Wrapper>
	);
};
