import { type FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { MultiDialogFlowContainer } from "@containers/multi-dialog-flow-container";
import { deleteAccountModalsFlow_AuthViewerSchemaFragment$key } from "@relay/deleteAccountModalsFlow_AuthViewerSchemaFragment.graphql";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "@screens/account/parts/account-tab/parts/delete-account-modals-flow/delete-account-modals-flow.graphql";
import { selectCurrentAccountId } from "@slices/auth/auth.selectors";
import { DeleteAccountModalsFlowProps } from "./delete-account-modals-flow.types";
import { ConfirmDeleteAccountModal } from "./parts/confirm-delete-account-modal";
import { DeleteAccountModal } from "./parts/delete-account-modal";
import { SuccessDeleteAccountModal } from "./parts/success-delete-account-modal";

export const DeleteAccountModalsFlow: FC<DeleteAccountModalsFlowProps> = ({
	isVisible,
	onDismiss,
	startAtStep,
	authViewerSchemaFragmentRef,
}) => {
	const createHandler = (handler: (step?: number) => void, step?: number) => {
		return () => {
			handler(step);
		};
	};
	const authFragment = useFragment<deleteAccountModalsFlow_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);
	const currentAccountId = useSelector(selectCurrentAccountId);
	const accountName = useMemo(
		() =>
			authFragment.currentUser?.accounts.find((a) => a.id == currentAccountId)?.name ??
			"Kontoname",
		[authFragment.currentUser?.accounts, currentAccountId],
	);

	return (
		<MultiDialogFlowContainer
			isVisible={isVisible}
			startAtStep={startAtStep}
			onDismiss={onDismiss}
		>
			{({ onNext, onClose }) => (
				<>
					<DeleteAccountModal
						isVisible
						onDismiss={createHandler(onClose)}
						onNext={createHandler(onNext)}
						accountName={accountName}
					/>
					<ConfirmDeleteAccountModal
						isVisible
						onSuccess={createHandler(onNext)}
						onDismiss={createHandler(onClose)}
						accountName={accountName}
					/>
					<SuccessDeleteAccountModal
						isVisible
						onDismiss={createHandler(onClose)}
						accountName={accountName}
					/>
				</>
			)}
		</MultiDialogFlowContainer>
	);
};
