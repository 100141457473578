import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, JwtTokenData } from "@slices/auth/auth.types";
import {
	removeParsedLoginData,
	removeParsedUserData,
	updateParsedLoginData,
	updateParsedUserData,
} from "@slices/auth/auth.utils";

export const setLoggedIn = (
	state: AuthState,
	action: PayloadAction<{
		tokenData: JwtTokenData;
		firstLogin?: boolean;
		isAvgsClient?: boolean;
	}>,
) => {
	state.isLoggedIn = true;
	state.loginData = action.payload.tokenData;
	state.firstLogin = action.payload.firstLogin ?? false;
	state.isAvgsClient = action.payload.isAvgsClient ?? false;
	updateParsedLoginData(action.payload.tokenData);
	updateParsedUserData({ isAvgsClient: state.isAvgsClient });
};

export const refreshLogin = (
	state: AuthState,
	action: PayloadAction<{ loginData: JwtTokenData }>,
) => {
	state.isLoggedIn = true;
	state.loginData = action.payload.loginData;
	updateParsedLoginData(action.payload.loginData);
};

export const logout = (state: AuthState) => {
	state.isLoggedIn = false;
	state.loginData = undefined;
	state.firstLogin = false;
	state.isAvgsClient = false;
	state.redirectUrl = undefined;
	removeParsedLoginData();
	removeParsedUserData();
};

export const setRedirectAfterLoginUrl = (
	state: AuthState,
	action: PayloadAction<string | undefined>,
) => {
	state.redirectUrl = action.payload;
	updateParsedUserData({ redirectUrl: action.payload });
};
