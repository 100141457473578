import { HasRequiredPermissionsType } from "@navigation/filtered-routes/filtered-routes.types";
import { Permission } from "@relay/permissionBasedNavigation_Query.graphql";
import { Path } from "@router/paths";

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(
	items: ItemType[],
	isLoggedIn: boolean,
	isFirstLogin: boolean,
	isAvgsClient: boolean,
	permissionsInAccount?: Permission[],
) {
	return items.filter((route) => {
		if (isAvgsClient) {
			return route.path === Path.avgsPotentialAnalysis.withIdPlaceholder().path;
		}
		const firstLogin =
			route.requiredPermissions === "first-login" && isLoggedIn && isFirstLogin;
		const loggedOutAndRouteRequiresLoggedOut =
			route.requiredPermissions === "only-logged-out" && !isLoggedIn;
		const loggedInAndRouteOnlyRequiredLoggedIn =
			route.requiredPermissions === "logged-in" && isLoggedIn;
		const loggedInOrLoggedOut = route.requiredPermissions === "logged-in-and-logged-out";

		const hasNecessaryPermissions =
			permissionsInAccount &&
			Array.isArray(route.requiredPermissions) &&
			route.requiredPermissions.every((permission) =>
				permissionsInAccount.includes(permission),
			);

		const isSystemOwner = permissionsInAccount?.includes(
			"UserInAccountPermission_System_Owner",
		);

		const hasAccess = hasNecessaryPermissions || isSystemOwner;

		return (
			firstLogin ||
			loggedOutAndRouteRequiresLoggedOut ||
			loggedInAndRouteOnlyRequiredLoggedIn ||
			loggedInOrLoggedOut ||
			hasAccess
		);
	});
}
