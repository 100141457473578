import { createSelector } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";
import { JwtClaimData } from "@slices/auth/auth.types";
import { ReduxState } from "@store/store";

const selectAuthSlice = (state: ReduxState) => state.auth;

export const selectJwtClaimData = createSelector(selectAuthSlice, (state) => {
	if (state.loginData) {
		return decodeToken<JwtClaimData>(state.loginData.accessToken);
	} else {
		return null;
	}
});
export const selectIsLoggedIn = createSelector(selectAuthSlice, (state) => state.isLoggedIn);
export const selectLoginData = createSelector(selectAuthSlice, (state) => {
	return state.loginData;
});

export const selectFirstLogin = createSelector(selectAuthSlice, (state) => {
	return state.firstLogin;
});

export const selectIsAvgsClient = createSelector(selectAuthSlice, (state) => {
	return state.isAvgsClient;
});

export const selectRedirectUrl = createSelector(selectAuthSlice, (state) => {
	return state.redirectUrl;
});

export const selectCurrentAccountId = createSelector(selectJwtClaimData, (jwtClaimData) => {
	return jwtClaimData?.accountId ? btoa(`Account:${jwtClaimData?.accountId}`) : undefined;
});

export const selectRefreshToken = createSelector(selectLoginData, (loginData) => {
	return loginData?.refreshToken;
});
