import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUpAGBStep } from "@components/sign-up-agb-step";
import { SignUpConfirmEmailStep } from "@components/sign-up-confirm-email-step";
import { SignUpJobStep } from "@components/sign-up-job-step";
import { SignUpNameStep } from "@components/sign-up-name-step";
import { SignUpPasswordEmailStep } from "@components/sign-up-password-email-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { useAuthContext } from "@hooks/use-auth-context";
import { SignUpFormValues } from "@hooks/use-auth-context/use-auth-context.types";
import { signUpSteps_InvitationByTokenQuery } from "@relay/signUpSteps_InvitationByTokenQuery.graphql";
import { Path } from "@router/paths";
import { InlineWrapper } from "./sign-up-steps.styles";
import { SignUpLocationState, SignUpStepsProps } from "./sign-up-steps.types";
import { AuthTemplateStep } from "../auth-template-step";
import { INVITATION_QUERY } from "../login-steps/login-steps.graphql";

export const SignUpSteps = ({ inline, onGoToLogin }: SignUpStepsProps) => {
	const navigate = useNavigate();
	const { signUp, acceptInvitationWithNewUser, redirectAfterLogin } = useAuthContext();
	const { state }: SignUpLocationState = useLocation();
	const { t } = useTranslation("inviteScreens");
	const [signUpEmail, setSignUpEmail] = useState<string | undefined>();

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<signUpSteps_InvitationByTokenQuery>(INVITATION_QUERY, {
		token: state?.invitationToken ?? "",
		skip: !state?.invitationToken,
	});

	const handleOnSubmit = (values: SignUpFormValues, goToNext: () => void) => {
		if (state?.invitationToken) {
			acceptInvitationWithNewUser(values, state.invitationToken).then((response) => {
				if (inline) return;

				redirectAfterLogin(
					response.Auth.acceptInvitationWithNewUser?.loginResult.forwardToFrontendURL,
				);
			});
		} else {
			signUp(values).then(() => {
				setSignUpEmail(values.email);
				goToNext();
			});
		}
	};

	const handleGoToLogin = () => {
		if (onGoToLogin) return onGoToLogin();
		navigate(Path.login.path, { state });
	};

	const inviteText =
		invitation?.accountName && invitation?.invitingUserName
			? t("invite_screens.invitedByTitle", {
					account: invitation.accountName,
					invitingUser: invitation.invitingUserName,
			  })
			: undefined;

	const wrapper = inline ? InlineWrapper : AuthTemplateStep;

	return (
		<MultiStepAuthContainer submitStep={4} StepWrapper={wrapper} onSubmit={handleOnSubmit}>
			{({ onNext, onPrevious }) => (
				<>
					<SignUpPasswordEmailStep
						currentStep={1}
						maxSteps={4}
						headerText={inviteText}
						onNext={onNext}
						onGoToLogin={handleGoToLogin}
					/>
					<SignUpNameStep
						currentStep={2}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpJobStep
						currentStep={3}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpAGBStep
						currentStep={4}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpConfirmEmailStep
						email={signUpEmail ?? ""}
						showLoginButton={inline}
						onGoToLogin={handleGoToLogin}
					/>
				</>
			)}
		</MultiStepAuthContainer>
	);
};
