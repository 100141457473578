import { createSlice } from "@reduxjs/toolkit";

import {
	logout as logoutReducer,
	refreshLogin as refreshLoginReducer,
	setLoggedIn as setLoggedInReducer,
	setRedirectAfterLoginUrl as setRedirectAfterLoginUrlReducer,
} from "@slices/auth/auth.reducers";
import { getInitialAuthState } from "@slices/auth/auth.utils";

const authSlice = createSlice({
	name: "auth",
	initialState: getInitialAuthState(),
	reducers: {
		setLoggedIn: setLoggedInReducer,
		refreshLogin: refreshLoginReducer,
		logout: logoutReducer,
		setRedirectAfterLoginUrl: setRedirectAfterLoginUrlReducer,
	},
});

export const { setLoggedIn, refreshLogin, logout, setRedirectAfterLoginUrl } = authSlice.actions;

export const AuthSliceReducer = authSlice.reducer;
