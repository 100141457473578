import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteObject } from "react-router";
import { Navigate, Route as DOMRoute, Routes, useLocation } from "react-router-dom";
import { useAuthContext } from "@hooks/use-auth-context";
import { FilteredRoutesProps } from "@navigation/filtered-routes/filtered-routes.types";
import { filterByPermission } from "@navigation/filtered-routes/filtered-routes.utils";
import { Path } from "@router/index";
import { PlacementContext } from "@screens/placement/placement.context";
import {
	selectFirstLogin,
	selectIsAvgsClient,
	selectIsLoggedIn,
	selectRedirectUrl,
} from "@slices/auth/auth.selectors";
import { setRedirectAfterLoginUrl } from "@slices/auth/auth.slice";

export const FilteredRoutes = ({ permissions, forbiddenRoute, routes }: FilteredRoutesProps) => {
	const dispatch = useDispatch();

	const firstLogin = useSelector(selectFirstLogin);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const redirectUrl = useSelector(selectRedirectUrl);
	const isAvgsClient = useSelector(selectIsAvgsClient);

	const { setPlacementLinkId } = useContext(PlacementContext);

	const filteredRoutes = filterByPermission(routes, isLoggedIn, firstLogin, isAvgsClient, [
		...(permissions || []),
	]);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const linkId = searchParams.get("linkId");
	const { logout } = useAuthContext();
	const redirectToLogin = !isLoggedIn || !!linkId;

	let parsedRedirectUrl: URL | undefined;

	try {
		if (redirectUrl) {
			parsedRedirectUrl = new URL(redirectUrl);
		}
	} catch (error) {
		parsedRedirectUrl = undefined;
	}
	const relativeRedirectUrl = parsedRedirectUrl
		? parsedRedirectUrl.pathname + parsedRedirectUrl.search
		: undefined;

	function renderNestedDomRoutes(routes?: RouteObject[]) {
		return routes?.map((route) => (
			<DOMRoute key={route?.path} path={route?.path} element={route?.element}>
				{renderNestedDomRoutes(route.children)}
			</DOMRoute>
		));
	}

	useEffect(() => {
		if (!isLoggedIn && location.pathname !== "/login") {
			const currentPath = location.pathname + location.search;
			if (!redirectUrl)
				dispatch(setRedirectAfterLoginUrl(window.location.origin + currentPath));
		}
	}, [dispatch]);

	useEffect(() => {
		if (linkId) {
			setPlacementLinkId(linkId);
			logout();
		}
	}, [linkId]);

	const getForbiddenRouteElement = () => {
		if (forbiddenRoute) {
			return forbiddenRoute;
		}

		const redirectPath = redirectToLogin
			? Path.login.path
			: relativeRedirectUrl ?? Path.dashboard.path;

		return <Navigate replace to={redirectPath} />;
	};

	return (
		<Routes>
			{filteredRoutes.map((permissionBasedRouteDefinition) => {
				return (
					<DOMRoute
						key={permissionBasedRouteDefinition.path}
						path={permissionBasedRouteDefinition.path}
						element={permissionBasedRouteDefinition.element}
					>
						{renderNestedDomRoutes(permissionBasedRouteDefinition.children)}
					</DOMRoute>
				);
			})}
			<DOMRoute key={"verboten"} path={"*"} element={getForbiddenRouteElement()} />
		</Routes>
	);
};
