import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AuthSliceReducer } from "@slices/auth/auth.slice";
import { CurrentUserSliceReducer } from "@slices/CurrentUserSlice";
import { FeatureTogglesSliceReducer } from "@slices/feature-toggles-slice";
import { VocabularySliceReducer } from "@slices/vocabulary.slice";

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		currentUser: CurrentUserSliceReducer,
		vocabulary: VocabularySliceReducer,
		featureToggles: FeatureTogglesSliceReducer,
	},
});
export type ReduxState = ReturnType<typeof ReduxStore.getState>;
export type TypedDispatch = typeof ReduxStore.dispatch;

export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
