import { z } from "zod";

export const LOCAL_STORAGE_LOGIN_DATA_KEY = "tkt-login-data";
export const LOCAL_STORAGE_USER_DATA_KEY = "tkt-user-data";

export const JwtTokenDataSchema = z.object({
	accessToken: z.string(),
	refreshToken: z.string(),
});

export const UserDataSchema = z.object({
	isAvgsClient: z.boolean().optional(),
	redirectUrl: z.string().optional(),
});
